import {Directive, EventEmitter, Input, Output} from "@angular/core";

/**
 * BaseFakeProgress used to share common logic of calculation progress value based on time and interval provided
 * could be stopped any time
 */
@Directive()
export abstract class BaseFakeProgress {
    @Input() show: boolean;
    @Input() timeToCounter: number;
    @Input() intervalTimeMs: number;
    @Input() randomRange: number = 2;
    @Input() enableFireProgress: boolean = false;
    @Output() fireProgress: EventEmitter<number> = new EventEmitter<number>();
    @Input() createdTime: string;

    mediaInterval;
    progressValue: number;
    isRunning: boolean;
    counter: number;

    protected constructor() {
        this.isRunning = false;
        this.progressValue = 0;
        this.counter = 0;
    }

    protected mainLogic = (changes) => {
        const {show} = changes;
        if(!show) return;

        if (show.currentValue && !this.isRunning) { // lock to not call more than once
            this.startProgressBar()
        } else if (!show.currentValue && this.isRunning) { // case when api done before maxInterval time done
            this.progressValue = 99;
            setTimeout(() => { // visual improvement add loading completion effect
                this.stopProgressBar();
            }, 1000)

        }
    }


    private stopProgressBar = () => {
        this.isRunning = false;
        clearInterval(this.mediaInterval);
        this.progressValue = 0;
        this.counter = 0;
    }

    protected startProgressBar = (): void => {
        this.isRunning = true;
        clearInterval(this.mediaInterval);

        if (this.createdTime) {
            const now = new Date().getTime();
            const createdTime = new Date(this.createdTime).getTime();
            const timeDifference = now - createdTime;


            if (timeDifference >= this.timeToCounter * 1000) {
                this.progressValue = 99;
                this.counter = this.timeToCounter - 1;
                if (this.enableFireProgress) {
                    this.fireProgress.emit(this.progressValue)
                }
            } else {
                this.progressValue =  Math.min(Math.ceil(((timeDifference / 1000) / this.timeToCounter) * 100), 99);
            }
        }

        this.mediaInterval = setInterval(() => {
            this.counter += 1;

            if (this.progressValue < 99) {
                if(this.createdTime) {
                    const now = new Date().getTime();
                    const createdTime = new Date(this.createdTime).getTime();
                    const timeDifference = now - createdTime;

                    // Calculate progress based on elapsed time and total time
                    const progress = (timeDifference / 1000) / this.timeToCounter;
                    this.progressValue = Math.min(Math.ceil(progress * 100),99); // Update progress based on elapsed time
                }else {
                    this.progressValue = Math.min(this.progressValue + this.randomSteps(), 99);
                }
                if (this.enableFireProgress) {
                    this.fireProgress.emit(this.progressValue)
                }
            }
            if (this.progressValue >= 99) {
                this.progressValue = 99;
            }
            if (this.counter >= this.timeToCounter - 1 && !this.show) { // case that it takes more than max time
                this.stopProgressBar();
            }
        }, this.intervalTimeMs);
    }

    protected randomSteps = (): number => {
        return Math.floor(Math.random() * this.randomRange); // if max = 3 , output 0 or 1 or 2
    }

}